@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lora:400,700");



.text {
  font-family: "Lora", serif;
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
}

a {
  cursor: pointer;
}

strong {
  margin-top: 16px;
  display: block;
  font-weight: 700;
}

p {
  padding: 24px;
  max-width: 760px;
  font-size: 22px;
  font-weight: 300;
  line-height: 1.9;
}

.link-1 {
  position: relative;
  text-decoration: none;
  display: inline-block;
  color: black;
  padding: 0 1px;
  margin-left: 6px;
  transition: color ease 0.3s;
}

.link-1::after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 5%;
  left: 0;
  bottom: 0;
  background-color: #00b388;
  transition: all ease 0.3s;
}

.link-1:hover {
  color: white;
}

.link-1:hover::after {
  height: 100%;
}

.link-2 {
  position: relative;
  text-decoration: none;
  display: inline-block;
  color: black;
  padding: 0 1px;
  transition: color ease 0.3s;
}

.link-2::before,
.link-2::after {
  content: "";
  position: absolute;
  background-color: #00b388;
  z-index: -1;
  height: 5%;
}

.link-2::before {
  width: 0%;
  left: 0;
  bottom: 0;
  transition: width ease 0.4s;
}

.link-2::after {
  width: 100%;
  left: 0;
  bottom: 0;
  transition: all ease 0.6s;
}

.link-2:hover::before {
  width: 100%;
}

.link-2:hover::after {
  left: 100%;
  width: 0%;
  transition: all ease 0.2s;
}

body {
  font-family: "Bebas Neue", cursive;
  font-size: 20px;
  letter-spacing: 1px;
}

.ssui-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
#header a {
  color: black;
  text-decoration: none;
}

#header {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px;
  font-size: 22px;
}

.name {
  font-size: 5rem;
}
#header .col,
.content-container .col {
  flex: 1;
  text-align: center;
}

#header .row {
  display: flex;
  flex-flow: row;
  justify-content: space-around;
}

.content-container .col:nth-child(1) {
  background-size: 181%;
  margin: 80px;
}

.content-container .col:nth-child(3) {
  background-size: 200%;
  background-position: left;
  margin: 80px;
}

.floating {
  position: absolute;
  left: 0;
  top: 10rem;
  right: 0;
  bottom: 0;
  font-size: 30rem;
  color: white;
}

.content-container .col {
  background-image: url("https://source.unsplash.com/random");
  background-size: cover;
  margin: 15px;
  border-radius: 100px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
}

.content-container {
  display: flex;
  margin: 15px;
  border-radius: 100px;
  flex-flow: row;
  height: 70%;
  background-color: #00000008;

  justify-content: space-around;
}


.ssui-icon {
  position: absolute;
  left: 0;
  right: -5px;
  top: 50%;
}